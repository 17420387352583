<template>
  <div>
      <div class="sticky-toolbar">
        <b-progress v-if="is_ended==false" :value="current_time" :max="max_time" :precision="2" show-progress class="mb-3"></b-progress>
    </div>
    <div v-for="(question1, index1) in data_questions" :key="index1" >
    <b-alert
        v-if="soal_show == false && question1.IsExample == '1' && is_ended == false"  
        show
        variant="light"
        class="alert alert-white alert-shadow fade show gutter-b"
    >
    <div>
       <h4> Waktu (Menit) : {{question1.items[0].Times}}</h4>
        <h4> Pilihlah Jawaban Yang Paling Mendekati / Paling Menunjukkan Gambaran Diri Anda</h4>
        <h2>Contoh Soal</h2> 
      <div class="row">
        <div class="col-auto ml-auto">
          <b-button variant="primary" @click="goToNextQuestion()">Next</b-button>
        </div>
      </div>
    </div>

    <b-alert
      show
      variant="light"
      class="alert alert-white alert-shadow fade show gutter-b"
    >

      <b-alert
          v-for="(question, index) in question1.items"
          :key="index"
          show
          variant="light"
          class="alert alert-white alert-shadow fade show gutter-b"
        >
        <h6>{{question.QuestionLabel}}</h6>
        <div class="alert-text">
            <hr>
            <div>
              <b-row>
                  <b-col sm="12">
                    <b-form-textarea
                      disabled
                      :id="'text-area1-'+index+'-'+index1"
                      placeholder="Auto height textarea"
                      rows="3"
                      max-rows="8"
                      v-model="question.QuestionDescription"
                    ></b-form-textarea>
                  </b-col>
                </b-row>
            </div>
        </div>
        <hr>
      </b-alert>

    <div class="row d-flex justify-content-center" >
      <b-form-radio-group
        stacked
        :id="'radio-group-'+index1"
        v-model="question1.AnswerQuestionID"
        :options="question1.items"
        value-field="QuestionID"
        text-field="QuestionLabel"
        :name="'radio-group-'+index1"
        size="lg" 
        @change="e=>{gantiJawaban(e, question1)}"
      ></b-form-radio-group>
    </div>

    </b-alert>

    </b-alert>

    <!-- real soal -->
    <b-alert
      v-if="soal_show == true && question1.IsExample == '0' && is_ended == false"   
      show
      variant="light"
      class="alert alert-white alert-shadow fade show gutter-b"
    >
    <div>
        <h2>Soal {{ question1.QuestionNumber }}</h2> 
      <div class="row">
        <div v-if="index1 == 1 || index1 == (data_questions.length - 1)" class="col-auto ml-auto">
          <b-button variant="primary" @click="goToEnd()">Selesai</b-button>
        </div>
      </div>
    </div>

    <b-alert
      show
      variant="light"
      class="alert alert-white alert-shadow fade show gutter-b">

      <b-alert
          v-for="(question, index) in question1.items"
          :key="index"
          show
          variant="light"
          class="alert alert-white alert-shadow fade show gutter-b"
        >
        <h6>{{question.QuestionLabel}}</h6>
        <div class="alert-text">
            <hr>
            <div>
              <b-row>
                  <b-col sm="12">
                    <b-form-textarea
                      disabled
                      :id="'text-area1-'+index+'-'+index1"
                      placeholder="Auto height textarea"
                      rows="3"
                      max-rows="8"
                      v-model="question.QuestionDescription"
                    ></b-form-textarea>
                  </b-col>
                </b-row>
            </div>
        </div>
        <hr>
      </b-alert>

    <div class="row d-flex justify-content-center" >
      <b-form-radio-group
        stacked
        :id="'radio-group-'+index1"
        v-model="question1.AnswerQuestionID"
        :options="question1.items"
        value-field="QuestionID"
        text-field="QuestionLabel"
        :name="'radio-group-'+index1"
        size="lg" 
        @change="e=>{gantiJawaban(e, question1)}"
      ></b-form-radio-group>
    </div>

    </b-alert>

    </b-alert>
    </div>

    <b-alert v-if="is_ended == true"  
        show
        variant="light"
        class="alert alert-custom alert-white alert-shadow fade show gutter-b"><H1>Terimakasih sudah mengikuti ujian ini 😊</H1>       
        <a
          class="btn btn-light-primary font-weight-bold"
          @click="onLogout"
          >Sign Out</a
        >
     </b-alert>
  </div>
</template>

<script>
import Services from "@/core/services/psikologi-api/Services";
import ApiService from "@/core/services/api.service";
import Swal from 'sweetalert2'
import localStorage from "@/core/services/store/localStorage";
import { LOGOUT } from "@/core/services/store/auth.module";

export default {
  data() {
    return {
      is_ended: false,
      soal_show: false,
      selected: ['b'],
      Number: 0,
      current_time: 0,
      max_time: 0,
      timerRunning: false,
      remainingTime: 0,
      timerInterval: null,
      data_questions: [],
      data_questions_cache: [],
      soal: 0,
      off: 0,
      maxSelections: 1,
      optionsboxes: [{value: "1", text: "Suka"}, {value: "0", text: "Tidak Suka"}],
      answerList : [],
      historyList: [],
      data_questions_groupby_number: [],
    };
  },
  watch: {
    selected(newVal) {
      const Val = newVal.slice(-1);
      if (newVal.length > this.maxSelections) {
    
        this.$nextTick(() => {
          this.selected = Val; 
        });
      }
    },
    async remainingTime(newVal) {
      // Update value progress bar
      this.current_time = newVal;

      // Reset progress bar jika waktu telah habis
      if (newVal == 0) {
        clearInterval(this.timerInterval);
        // this.current_time = 0;
        this.is_ended = true
        await this.updateParticipantHistory(newVal)
      }
    }
  },
  methods: {
    startTimer() {
    if (!this.timerRunning && this.remainingTime > 0) { // Tambahkan pengecekan timerRunning
      this.timerRunning = true; // Set timerRunning menjadi true
      // Set interval untuk mengurangi remainingTime setiap detik
      this.timerInterval = setInterval(() => {
        this.remainingTime--;
      }, 1000);
    }
  },

    stopTimer() {
        clearInterval(this.timerInterval);
    },
    onLogout() {
      // localStorage.setLocalStorage('Number', 1);
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },

     async goToEnd(){
        this.is_ended = true
        await this.updateParticipantHistory(0)
    },

    limitAndBreakText(text, limit) {
    const words = text.split(" ");
    let result = "";
    let count = 0;

    for (let i = 0; i < words.length; i++) {
      count += words[i].length;

      if (count <= limit) {
        result += words[i] + " ";
      } else {
        result = result.trim() + "<br>";
        count = 0;
        i--; // Re-process the current word in the next iteration
      }
    }

    return result.trim();
  },

  createBox(data){
      var result = []

      var foundObj = data.find((element) => element.KeyList != "")
      var optionsBoxsString = foundObj.KeyList
      var optionsBoxsArr = optionsBoxsString.split(",")
      // optionsBoxsArr = optionsBoxsArr.sort((a, b) => a - b)

      for (var element of optionsBoxsArr) {
        result.push({text: element.toUpperCase(), value: element.toUpperCase()})
      }
      return result
  },

  updateParticipantHistory(times){
      const formData = new FormData();
      formData.append("Times", times);
      formData.append("ParticipantId", this.$route.params.ParticipantID);
      formData.append("Session", this.$route.params.TypeTestID);

      let contentType = `application/form-data`;
      return new Promise((resolve, reject) => {
        Services.PostData(
           ApiService,
          `process/participant_history/updatetimes/`,
          formData,
          contentType,
          response => {
            if (response.status) {
              // Swal.fire({
              //   title: "",
              //   text: "Data saved successfully.",
              //   icon: "success",
              //   heightAuto: true,
              //   timer: 1500
              // });

            } else {
              // Swal.fire({
              //   title: "",
              //   text: response.data.error,
              //   icon: "info",
              //   heightAuto: true,
              //   timer: 1500
              // });
            }
          },
          error => {
            console.error('Error during API call:', error);
          }
        );
      });
  },

  getHistory(){
    return new Promise((resolve, reject) => {
        let contentType = `application/form-data`;
        Services.GetId(
          ApiService,
          `process/participant_history/getbytokenandparticipant`,
          this.$route.params.ParticipantID + "/" + this.$route.params.TypeTestID,
          contentType,
          response => {
            resolve(response.data);
            this.historyList = []
            this.historyList = response.data
          },
          err => {

          }
        );
      });
  },

   getAnswer() {
      return new Promise((resolve, reject) => {
        let contentType = `application/form-data`;

        Services.GetId(
          ApiService,
          `process/participant/answer-papi-kostick/getbytokenandparticipant`,
          this.$route.params.ParticipantID + "/" + this.$route.params.TypeTestID,
          contentType,
          response => {
            resolve(response.data);
            this.answerList = []
            this.answerList = response.data
          },
          err => {

          }
        );
      });
    },

    getMasterQuestions() {
      return new Promise((resolve, reject) => {
        let contentType = `application/form-data`;
        let number = localStorage.getLocalStorage("Number");
        if (number === null) {
            number = 0;
        }

        Services.GetId(
          ApiService,
          `master/questions-papi-kostick/byid`,
          this.$route.params.TypeTestID + "?QuestionNumber=" + number,
          contentType,
          response => {
            resolve(response.data);
            this.data_questions_cache = response.data

            this.data_questions_groupby_number = this.groupByManual(this.data_questions_cache, ['IsExample','QuestionNumber'])
            this.data_questions_groupby_number = this.data_questions_groupby_number.sort((a, b) => a.QuestionNumber - b.QuestionNumber);

          },
          err => {

          }
        );
      });
    },

    groupByManual(dataSourceList, arrStringProp){
        var dataList = [];
        var size = arrStringProp.length;
        
        for(let obj of dataSourceList){
            let opt = dataList.find((p)=>{
                let string = "";
                let idx = 1;
                for(let prop of arrStringProp){
                    string+= " p['"+prop+"'] == obj['"+prop+"']";
                    if(idx<size)string += " && ";
                    idx++;
                }
                string+= " ;";
                if(string!="")return eval(string);
                else return false;
            })
    
            if(opt){
                opt.items.push(obj);
            }else{
                let newObj = {items: []};
                for(let prop of arrStringProp){
                    newObj[prop] = obj[prop];
                }
                newObj['items'].push(obj);
                dataList.push(newObj)
            }
        }
        return dataList;
    },

    goToNextQuestion() {
      this.soal_show = true
      this.startTimer();
    },

    compareQuestionAndAnswer(){
        // for(var i=0; i<this.data_questions_cache.length; i++){
        //   // this.data_questions[i]['AnswerContent'] = []
        //   this.data_questions_cache[i]['AnswerContent']  = ""
        //   this.data_questions_cache[i]['AnswerId']  = ""
        //   var foundObj = this.answerList.find((element) => element.QuestionID == this.data_questions_cache[i]['QuestionID'])
        //   if(foundObj != undefined){
        //     this.data_questions_cache[i]['AnswerContent'] = foundObj.AnswerContent
        //     this.data_questions_cache[i]['AnswerId'] = foundObj.AnswerId
        //   }
        // }
        // return this.data_questions_cache

        for(var i=0; i<this.data_questions_groupby_number.length; i++){
            this.data_questions_groupby_number[i]['AnswerContent'] = ""
            this.data_questions_groupby_number[i]['AnswerId'] = ""
            this.data_questions_groupby_number[i]['AnswerQuestionID'] = ""

          for(var j=0; j<this.data_questions_groupby_number[i].items.length; j++){
            this.data_questions_groupby_number[i].items[j]['AnswerContent'] = ""
            this.data_questions_groupby_number[i].items[j]['AnswerId'] = ""
            this.data_questions_groupby_number[i].items[j]['AnswerQuestionId'] = ""
            this.data_questions_groupby_number[i].items[j]['IsChecked'] = false
             this.data_questions_groupby_number[i].items[j]['QuestionLabel'] = `Pernyataan ${j+1}`
            var foundObj = this.answerList.find((element) => element.QuestionID == this.data_questions_groupby_number[i].items[j]['QuestionID'])
            if(foundObj != undefined){
              this.data_questions_groupby_number[i].items[j]['AnswerContent'] = foundObj.AnswerContent
              this.data_questions_groupby_number[i].items[j]['AnswerId'] = foundObj.AnswerId
              this.data_questions_groupby_number[i].items[j]['AnswerQuestionID'] = foundObj.QuestionID
              this.data_questions_groupby_number[i].items[j]['IsChecked'] = true 

              this.data_questions_groupby_number[i]['AnswerContent'] = foundObj.AnswerContent
              this.data_questions_groupby_number[i]['AnswerId'] = foundObj.AnswerId
              this.data_questions_groupby_number[i]['AnswerQuestionID'] = foundObj.QuestionID
            }
          }
        }
        return this.data_questions_groupby_number
    },

    async gantiJawaban(newVal, groupObj){
      groupObj.AnswerContent = groupObj.QuestionNumber
      await this.saveJawaban(groupObj)
    },

    saveJawaban(obj) {
      const formData = new FormData();
      formData.append("AnswerId", obj.AnswerId);
      formData.append("AnswerContent", obj.AnswerContent);
      formData.append("QuestionId", obj.AnswerQuestionID);
      formData.append("ParticipantId", this.$route.params.ParticipantID);
      formData.append("Token", this.$route.params.TypeTestID);
      formData.append("RemainingTime", this.remainingTime)

      let contentType = `application/form-data`;
      return new Promise((resolve, reject) => {
        Services.PostData(
           ApiService,
          `process/participant/answer-papi-kostick/saveupdateanswer/`,
          formData,
          contentType,
          response => {
            if (response.status) {
              // Swal.fire({
              //   title: "",
              //   text: "Data saved successfully.",
              //   icon: "success",
              //   heightAuto: true,
              //   timer: 1500
              // });

            } else {
              // Swal.fire({
              //   title: "",
              //   text: response.data.error,
              //   icon: "info",
              //   heightAuto: true,
              //   timer: 1500
              // });
            }
          },
          error => {
            console.error('Error during API call:', error);
          }
        );
      });
    },

    findCurrentTime(history_list){
      var result = -1

      for(var i=0; i<history_list.length; i++){
          result = history_list[i].Times
      }
      return result
    },

    findMaxTime(data_questions_list){
      var result = 0
          var foundObj = data_questions_list.find((element) => element.IsExample == 1)
          if(foundObj != undefined)
            result = foundObj.items[0]['Times'] * 60

          return result
    },

    checkEnded(max, curr){
        this.remainingTime = curr
        if(curr == -1)
          this.remainingTime = max

        var is_ended = false
        if(curr == 0)
          is_ended = true

        return is_ended
    },

    async loadData() {
      Promise.all([await this.getHistory(), await this.getAnswer(), await this.getMasterQuestions()]).then((values) => {
         this.data_questions = this.compareQuestionAndAnswer()
         this.max_time = this.findMaxTime(this.data_questions)
         this.current_time = this.findCurrentTime(this.historyList)
         this.is_ended = this.checkEnded(this.max_time, this.current_time)
      });
    },

    handleBackButton(event) {
      window.history.pushState(null, null, window.location.pathname);
    }
  },
  mounted() {

    this.loadData();
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', this.handleBackButton);
  },
  beforeDestroy() {
    window.removeEventListener('popstate', this.handleBackButton);
  },
};
</script>

<style lang="scss" scoped>
.sticky-toolbar{
  width: 100%;
  top: 25%;
  align-items: normal;
}
</style>

